import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { useHistory } from "react-router-dom";
import logo from './../ImagesApp/logob.png';

function NavPortero (){

  const history = useHistory();

  function handleSalir(e) {
    e.preventDefault();
    history.push("/");
    history.go(0);
    localStorage.removeItem("token");

  }
  
    return(
    
      <div className =""  >
         <Navbar  variant="dark"  className='list-group-item-primary0 bg-info ' expand="lg">
         <img src={logo} className="" height='50px'  alt=""/>
             
                <Navbar.Brand> </Navbar.Brand>
              
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav >
              <LinkContainer to="/portero/inicio">
                <Nav.Link ><span className='font-weight-bold'><i className="icon ion-md-card "> </i>Inicio</span></Nav.Link>
                </LinkContainer>
                <LinkContainer to="/portero/busqueda">
                <Nav.Link><span className='font-weight-bold'><i className="icon ion-md-search "> </i>Busqueda</span></Nav.Link>
                </LinkContainer>
                <LinkContainer to="/portero/registro">
                <Nav.Link><span className='font-weight-bold'><i className="icon ion-md-person-add "> </i>Registro</span></Nav.Link>
                </LinkContainer>
                <span><button className='btn btn-outline-light ml-lg-4 d-flex d-right px-lg-3 px-4 font-weight-bold ' onClick={(e)=>handleSalir(e)}> <i className="icon ion-md-power h5 mr-2 m-0"> </i> Salir</button></span>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        
      </div>
      );
    
}


export {NavPortero};