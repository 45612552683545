import { useHistory } from "react-router-dom";

function ClienteList ({clientes, setClientes}){

    const history = useHistory();

    
    return(
    <div className='mb-4'>
        {
            clientes.map((cliente)=>{

    function handleVer(e) {
        e.preventDefault();
        history.replace(`perfil?id=${cliente.id}`);
        

    }
                return(
                <div>
                    <div className="w-100 bg-light d-flex justify-content-between  "> 
                        <div className='mt-2 pt-1 ml-3'>
                            <p className='font-weight-bold d-inline mr-md-3 text-info'> {cliente.numsocio}. </p>
                            <p className='d-inline'> {cliente.nombre_completo}</p>
                        </div> 
                        <button className="btn btn-outline-info my-2 py-0   mr-2 px-3 " onClick={(e)=>handleVer(e)}><i className="icon ion-md-eye   h4  "> </i></button>
                    </div>
                    <div className="progress w-100 col-12 mb-0  list-group-item-info0" Style="height: 2px;"> </div>
                </div>
                        
                 
                )
            })
        }
 

            

        
        

    </div>


    
    );
    
}


export {ClienteList};