import {useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";

function Configuracion (){

    const [usuarios, setUsuarios]= useState([{}]);
    const [abonos, setAbonos]= useState([{}]);
    const[ocultarA, setOcultarA]= useState({class: "d-none", icon: "arrow-dropdown-circle"})
    const[ocultarU, setOcultarU]= useState({class: "d-none", icon: "arrow-dropdown-circle"})
    const anos = [
        2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035,2036,2037,2038,2039,2040,
        2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052,2053,2054,2055,2056,2057,2058,2059,2060,
        2061,2062,2063,2064,2065,2066,2067,2068,2069,2070,2071,2072,2073,2074,2075,2076,2077,2078,2079,2080,
        2081,2082,2083,2084,2085,2086,2087,2088,2089,2090,2091,2092,2093,2094,2095,2096,2097,2098,2099,2100
    ];
    const [temporada, setTemporada] = useState(0);

    const [nanombre, setNANombre]= useState('');
    const [naobservaciones, setNAObservaciones]= useState('');
    const [naprecio, setNAPrecio]= useState('');
    
    const [nuusername, setNUUsername]= useState('');
    const [nupasword, setNUPasword]= useState('');
    const [nurol, setNURol]= useState('');
    
        useEffect(() => {
        
    
            /* ... FECH ... */
           fetch('https://clientes.piscinaelcarmen.com/public/index.php/datos',{
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
              } ,
            })
            .then( response => response.json())
            .then(
                response => {
                      setTemporada(response.datos.temporada)
                  })
                  .catch(
                     error=> console.log(error) 
            );

              /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos',{
                method: 'POST',
                mode: 'cors',
                headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
                } ,
            })
            .then( response => response.json())
            .then(response => { setAbonos(response.abonos)})
            .catch(error=> console.log(error));

            /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/usuarios',{
                method: 'POST',
                mode: 'cors',
                headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
                } ,
            })
            .then( response => response.json())
            .then(response => { setUsuarios(response.usuarios)})
            .catch(error=> console.log(error));
      
        }, [])



    function abonosHandle(e){
        e.preventDefault();
        if(ocultarA.class === ""){
            setOcultarA({class: "d-none", icon: "arrow-dropdown-circle"})
        }else{
            setOcultarA({class: "", icon: "arrow-dropup-circle"})

        }
        
    }
    function usuariosHandle(e){
        e.preventDefault();
        if(ocultarU.class === ""){
            setOcultarU({class: "d-none", icon: "arrow-dropdown-circle"})
        }else{
            setOcultarU({class: "", icon: "arrow-dropup-circle"})

        }
        
    }
    function temporadaGuardar(e) {
        e.preventDefault();
        Swal.fire({
            title: '¿Estas seguro?',
            text: "Cambiará la temporada",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#17a2b8',
            confirmButtonText: 'Si, Cambiar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {

                      /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/temporada',{
                method: 'POST',
                mode: 'cors',
                headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
                } ,
                body: JSON.stringify({
                   temporada: temporada,
                })  
                })
                .then( response => response.json())
                .then(response => {
                    setTemporada(response.temporada)
                    Swal.fire({
                        title: 'Nueva Temporada',
                        text: 'La temporada ha cambiado correctamente',
                        
                        icon: 'success',
                        showConfirmButton: false,
                        timer: "1500",
                        
                        })
                })
                .catch( );

              
            }
          })

    }

    function abonoAdd(e) {
        e.preventDefault();

        if(!((nanombre==='')||(naobservaciones ==='')||(naprecio ===''))){
            /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos/nuevo',{
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
              }   ,
              body: JSON.stringify({
                 nombre: nanombre,
                 observaciones: naobservaciones,
                 precio: naprecio,
              })  
            })
            .then( response => response.json())
            .then(
                response => {
                  
                    Swal.fire({
                        position: 'top-end',
                        title: 'Creado nuevo abono',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: "2000",
                        toast: true,
                        
                        })
    
                  setAbonos(response.abonos);
                  setNANombre('');
                  setNAObservaciones('');
                  setNAPrecio('');
                     
                  })
                  .catch(error =>( Swal.fire({
                    /* position: 'top-end', */
                    title: 'Error en el Registro',
                    text: 'Error en la conexión al servidor',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: "3000",
                    }) ));
              }else{
                Swal.fire({
                   position: 'top-end', 
                  title: 'ERROR: Campos erroneos o vacíos',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: "3000",
                  toast: true,
                  })
                }

    }
    function usuarioAdd(e) {
        e.preventDefault();

        if( !((nuusername==='')||(nupasword ==='')||(nurol ==='')) ){

            const decoded = jwt_decode(localStorage.getItem('token'));
                    
             if(!(nuusername===decoded.username )){
            
            /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/usuarios/nuevo',{
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
              }   ,
              body: JSON.stringify({
                 username: nuusername,
                 password:nupasword,
                 rol: nurol,
              })  
            })
            .then( response => response.json())
            .then(
                response => {
                  
                    Swal.fire({
                        position: 'top-end',
                        title: 'Creado nuevo usuario',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: "2000",
                        toast: true,
                        
                        })
    
                  setUsuarios(response.usuarios);
                  setNUUsername('');
                  setNUPasword('');
                  setNURol('');
                     
                  })
                  .catch(error =>( Swal.fire({
                    /* position: 'top-end', */
                    title: 'Error en al Registrar',
                    text: 'Error en la conexión al servidor',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: "3000",
                    }) ));

                }else{
                    Swal.fire({
                        title: 'Error en al Registrar',
                        text: 'Ya existe un usuario con ese nombre',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: "3000",
                        })

                }

              }else{
                Swal.fire({
                   position: 'top-end', 
                  title: 'ERROR: Campos erroneos o vacíos',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: "3000",
                  toast: true,
                  })
                }



        }

    

  
    return(
    
    <div className ="container mt-5 bg-light pt-3 px-5 border   rounded"  >

    <h2 className='font-weight-bold text-info'><i className="icon ion-md-cog"> </i> CONFIGURACIÓN:</h2>
    <div className="progress w-100 col-12 mb-2" Style="height: 2px;"> </div>
    <div className="d-md-flex justify-content-md-between ml-4 ">
        
        <h5 className="m-0 pt-2 mb-2 mb-md-0 "><i className="icon ion-md-medical text-info "> </i> Cambiar temporada:</h5>
        <div className="d-flex justify-content-between col-12 col-md-4 ">
        <select className="custom-select list-group-item-info d-md-block text-right  " onChange={(e)=>{setTemporada(e.target.value)}}>
                      <option disabled selected>- {temporada} -</option>
                      {
                        anos.map((ano)=>{
                          return(<option >{ano} </option>)
                        }
                      )}
                  </select>
                  <button className="btn btn-outline-info d-inline py-0 px-2 mb-4 ml-2" type="button" onClick={(e)=>temporadaGuardar(e)}><i className="icon ion-md-save h3 "> </i></button>
            </div>
    </div>
    <div className="progress w-100 col-12 mb-2 mt-2" Style="height: 2px;"> </div>

{/* Tipos De Abonos */}


        <div className="d-flex justify-content-between    ">
        <h5 className="m-0 pt-2 ml-4  mb-2"><i className="icon ion-md-medical text-info"> </i> Administar abonos:</h5>
                    <button className="btn text-secondary d-inline py-0 px-2 mr-2 " onClick={(e)=>abonosHandle(e)} type="button"><i className={`icon ion-md-${ocultarA.icon}  h4 `}> </i></button>
              </div>

<div className={ocultarA.class} >
        
        <h6 className="m-0 pt-2 ml-5  mb-2"><i className="icon ion-md-medical  text-info "> </i> Nuevo abono:</h6>
        <div className="form-row ml-5 mb-0 mt-3 ">
              <div className="form-group mb-2  mb-md-0 col-md-4">
                  <input type="text" className="form-control  mb-0"   placeholder="Nombre abono" onChange={(e)=>{setNANombre(e.target.value)}} value={nanombre}></input>
              </div>
              <div className="form-group mb-2 mb-md-0 col-md-5">
                  <input type="text" className="form-control  mb-0"  placeholder="Observaciones" onChange={(e)=>{setNAObservaciones(e.target.value)}} value={naobservaciones}></input>
              </div>
              <div className="d-flex justify-content-between col-md-2   ">
                <input type="number" className="form-control  mb-0"  placeholder="Precio" onChange={(e)=>{setNAPrecio(e.target.value)}} value={naprecio}></input>
                    <button className="btn btn-outline-info d-inline py-0 px-2 ml-2 " type="button" onClick={(e)=>abonoAdd(e)}><i className="icon ion-md-add  h3 "> </i></button>
              </div>
              
            </div>
            <h6 className="m-0 pt-2 ml-5  mt-3 mb-2"><i className="icon ion-md-medical  text-info "> </i> Tipos de abonos:</h6>

            {abonos.map((abono)=>{

                let nombre = abono.nombre;
                let observaciones =abono.observaciones;
                let precio =abono.precio;
                function abonoGuardar(e) {
                    e.preventDefault();
                    
                        if(!((nombre==='')||(observaciones ==='')||(precio ===''))){
                            /* ... FECH ... */
                            fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos/editar',{
                              method: 'POST',
                              mode: 'cors',
                              headers: {
                                'Content-Type': 'application/json',
                                "Authorization" : `Bearer ${localStorage.getItem('token')}`
                              }   ,
                              body: JSON.stringify({
                                  id: abono.id,
                                  nombre: nombre,
                                 observaciones: observaciones,
                                 precio: precio, 
                              })  
                            })
                            .then( response => response.json())
                            .then(
                                response => {
                                  
                                    Swal.fire({
                                        position: 'top-end',
                                        title: 'Abono Actualizado',
                                        icon: 'success',
                                        showConfirmButton: false,
                                        timer: "2000",
                                        toast: true,
                                        
                                        })
                    
                                     
                                  })
                                  .catch(error =>( Swal.fire({
                                    /* position: 'top-end', */
                                    title: 'Error en el Registro',
                                    text: 'Error en la conexión al servidor',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: "3000",
                                    }) ));
                              }else{
                                Swal.fire({
                                   position: 'top-end', 
                                  title: 'ERROR: Campos erroneos o vacíos',
                                  icon: 'error',
                                  showConfirmButton: false,
                                  timer: "3000",
                                  toast: true,
                                  })
                                }

                }
                function abonoEliminar(e) {
                    e.preventDefault();
                    Swal.fire({
                        title: '¿Estas seguro?',
                        text: "Se eliminara el Abono",
                        icon: 'warning',
                        showCancelButton: true,
                        cancelButtonColor: '#6c757d',
                        confirmButtonColor: '#17a2b8',
                        confirmButtonText: 'Si, Eliminar',
                        cancelButtonText: 'Cancelar'
                      }).then((result) => {
                        if (result.isConfirmed) {

                            /* ... FECH ... */
            fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos/eliminar',{
                method: 'POST',
                mode: 'cors',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization" : `Bearer ${localStorage.getItem('token')}`
                }   ,
                body: JSON.stringify({
                   id: abono.id,
                })  
              })
              .then( response => response.json())
              .then(
                  response => {
                    
                      Swal.fire({
                          position: 'top-end',
                          title: 'Abono eliminado',
                          icon: 'success',
                          showConfirmButton: false,
                          timer: "2000",
                          toast: true,
                          
                          })
      
                    setAbonos(response.abonos);
                       
                    })
                    .catch(error =>( Swal.fire({
                      title: 'Error no se puede eliminar',
                      text: 'Hay clientes con este abono, cambialo para poder eliminarlo',
                      icon: 'error',
                      showConfirmButton: true,
                      confirmButtonColor: '#17a2b8',
                      confirmButtonText: 'Aceptar',
                      
                      }) ));
                        }
                      })

                }
                
                

                    return(

            <div className="form-row ml-5 mb-0 mt-3 ">
              <div className="form-group mb-1  mb-md-0 col-md-4">
                  <input type="text" className="form-control table-dark font-weight-bold text-dark mb-0" placeholder='Nombre abono' onChange={(e)=>(nombre = e.target.value)} defaultValue={abono.nombre}  ></input>
              </div>
              <div className="form-group mb-1  mb-md-0 col-md-5">
                  <input type="text" className="form-control table-dark text-dark mb-0" placeholder='Observaciones' onChange={(e)=>(observaciones = e.target.value)}  defaultValue={abono.observaciones}  ></input>
              </div>
              <div className="d-flex justify-content-between col-8 col-md-2">
                  <input type="number" className="form-control table-dark text-dark mb-0 "  placeholder='Precio' onChange={(e)=>(precio = e.target.value)}  defaultValue={abono.precio}  ></input>
                    <button className="btn btn-outline-info d-inline py-0 px-2 ml-2" type="button"  onClick={(e)=>abonoGuardar(e)}><i className="icon ion-md-save  h3 "> </i></button>
                    <button  className="btn btn-outline-danger d-inline py-0 px-2 ml-1" type="button" onClick={(e)=>abonoEliminar(e)}><i className="icon ion-md-trash  h3 "> </i></button>
              </div>
              
            </div>
                    )

            })}
        

            
        </div>    
                
            <div className="progress w-100 col-12 mb-2 mt-4" Style="height: 2px;"> </div>

{/* Administrar Usuarios */}
            <div className="d-flex justify-content-between    ">
                <h5 className="m-0 pt-2 ml-4  mb-2"><i className="icon ion-md-medical text-info"> </i> Administrar usuarios:</h5>
                    <button className="btn text-secondary d-inline py-0 px-2 mr-2 " onClick={(e)=>usuariosHandle(e)} type="button"><i className={`icon ion-md-${ocultarU.icon}  h4 `}> </i></button>
              </div>
              <div className={ocultarU.class} >

        <h6 className="m-0 pt-2 ml-5  mb-2"><i className="icon ion-md-medical  text-info "> </i> Nuevo usuario:</h6>
        <div className="form-row ml-5 mb-0 mt-3 ">
              <div className="form-group mb-2  mb-md-0 col-md-4">
                  <input type="text" className="form-control  mb-0"   placeholder="Nombre usuario" onChange={(e)=>{setNUUsername(e.target.value)}} value={nuusername}></input>
              </div>
              <div className="form-group mb-2 mb-md-0 col-md-4">
                  <input type="password" className="form-control  mb-0"  placeholder="Contraseña" onChange={(e)=>{setNUPasword(e.target.value)}} value={nupasword}></input>
              </div>
              <div className="d-flex justify-content-between col-md-4    ">

                <select className="custom-select list-group-item-info   " onChange={(e)=>{setNURol(e.target.value)}}>
                        <option disabled selected>Rol</option>
                        <option>Administrador</option>
                        <option>Portero</option>
                    </select>
                    <button className="btn btn-outline-info d-inline py-0 px-2 ml-2 " type="button" onClick={(e)=>usuarioAdd(e)}><i className="icon ion-md-add  h3 "> </i></button>
              </div>
              
            </div>
            <h6 className="m-0 pt-2 ml-5  mt-3 mb-2"><i className="icon ion-md-medical  text-info "> </i> Lista usuarios:</h6>

            {usuarios.map((usuario)=>{

                function usuarioEliminar(e) {
                    e.preventDefault();
                    Swal.fire({
                        title: '¿Estas seguro?',
                        text: "Se eliminara el Usuario",
                        icon: 'warning',
                        showCancelButton: true,
                        cancelButtonColor: '#6c757d',
                        confirmButtonColor: '#17a2b8',
                        confirmButtonText: 'Si, Eliminar',
                        cancelButtonText: 'Cancelar'
                      }).then((result) => {
                        if (result.isConfirmed) {
                        
                        const decoded = jwt_decode(localStorage.getItem('token'));
                    
                        if(!(usuario.username===decoded.username )){


                                /* ... FECH ... */
                    fetch('https://clientes.piscinaelcarmen.com/public/index.php/usuarios/eliminar',{
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                        'Content-Type': 'application/json',
                        "Authorization" : `Bearer ${localStorage.getItem('token')}`
                        }   ,
                        body: JSON.stringify({
                        id: usuario.id,
                        })  
                    })
                    .then( response => response.json())
                    .then(
                        response => {
                            
                            Swal.fire({
                                position: 'top-end',
                                title: 'Usuario eliminado',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: "2000",
                                toast: true,
                                
                                })
            
                            setUsuarios(response.usuarios);
                            
                            })
                            .catch(error =>( Swal.fire({
                            title: 'Error no se puede eliminar',
                            text: 'Error en el servidor',
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#17a2b8',
                            confirmButtonText: 'Aceptar',
                            
                            }) ));
                         }else{
                            Swal.fire({
                                title: 'Error no se puede eliminar',
                                text: 'No puedes eliminar tu propio usuario',
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#17a2b8',
                                confirmButtonText: 'Aceptar',

                            })
                         }
                        }
                    })

                }

                    return(

            <div className="form-row ml-5 mb-0 mt-3 ">
              <div className="form-group mb-1  mb-md-0 col-md-8">
                  <input type="text" className="form-control table-dark text-dark mb-0" disabled value={usuario.username}  ></input>
              </div>
              <div className="d-flex justify-content-between col-md-4   ">
                    <select className="custom-select table-dark text-dark mb-0 text-right w-100"   disabled>
                        <option disabled selected>{usuario.rol}</option>
                    </select>
                    <button className="btn btn-outline-danger d-inline py-0 px-2 ml-2" type="button"onClick={(e)=>usuarioEliminar(e)}><i className="icon ion-md-trash  h3 "> </i></button>
              </div>
            </div>
                    )
                    
                })}
        </div>
             <div className="progress w-100 col-12 mb-4 mt-4" Style="height: 2px;"> </div>
           
            


  </div>
    );
    
}
export {Configuracion};