import { useHistory } from "react-router-dom";
import {useState,useEffect} from 'react';
import logo from './../ImagesApp/logo.png';

import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

function Login (){

    const history = useHistory();
    
    let username;
    let password;
    function usernamef(e){
        username = e.target.value;
    };
    function errorf(error){
        Swal.fire({
            title: 'Error!',
            text: "Usuario o Contraseña incorrecta",
            icon: 'error',
            showConfirmButton: false,
            timer: "2000",
            
            })
        }

    function passwordf(e){
        password= e.target.value;
        
    };
    function handleLogin(e){
    
        e.preventDefault();

        
        let credentials ={
            username: username,
            password: password
        }
     
      /* ... FECH ... */
      fetch('https://clientes.piscinaelcarmen.com/public/index.php/login',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      .then( response => response.json()
      .then(
          response => {
            console.log('Respuesta ok: ', response);
            localStorage.setItem('token', response.token );
            
            const decoded = jwt_decode(response.token);          
             console.log(decoded);
             
            var decodedHeader = jwt_decode(response.token, { header: true });
             console.log(decodedHeader);  

             console.log(decoded.roles);
             
             if(decoded.roles.includes("Administrador")){
                history.push("/admin/inicio")
             }else if(decoded.roles.includes("Portero")){
                history.push("/portero/inicio");
             }
            }
            )
            .catch(error=>  errorf(error)   ));
                
             
    };
  
    return(
    
       
    <div className ="container  d-flex justify-content-center "  >
        <div className="row ">
            <div className=' mb-4  '>
                <div className="d-flex  mt-5 justify-content-center">

            <img src={logo} className=" card-img-top mt-5 mb-5 col-12 col-md-8 col-lg-6"  alt=""/>
                </div>
                <div className="container bg-light border mt-5 col-10 col-md-6 rounded">
                <h2 className='font-weight-bold text-info mt-3'><i className="icon ion-md-contact"> </i> INICIAR SESIÓN: </h2>
                 <div className="progress w-100 col-12 mb-2" Style="height: 2px;"> </div>

                 <div className="form-group my-3  border-info rounded">

                     <form className="form-row w-100 ">
                         

                    <input type="text" id='user' className="form-control  border border-info " placeholder="Usuario" onChange = {(e)=>usernamef(e)}/>
                    <input type="password" id='pass' className="form-control  border border-info mt-3 " placeholder="Contraseña" onChange = {(e)=>passwordf(e)} />
                    
                     </form>
                </div>



                 <div className="progress w-100 col-12 my-3" Style="height: 2px;"> </div>

                    <div className="d-flex justify-content-end mb-3">
                        
                            <button type="button" className="btn btn-info " onClick={(e)=>handleLogin(e)}> <i className="icon ion-md-log-in "> </i> Login </button>
                        
                    </div>
                </div>
            </div>  
            
            <div className=' col-lg-4 '>
            
            

 

            

            </div>  
        </div>
      
    </div>
    );
    
}


export {Login};