import {useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Delay  from 'react-delay';
import { useHistory } from "react-router-dom";

function Consulta (){

   
    const[codigo, setCodigo]= useState( 0);
    const[textCodigo, setTextCodigo]= useState('');
    const [counter, setCounter] = useState(10);
    const [entradas, setEntradas] = useState(0);
    const [temporada, setTemporada] = useState(0);
    const [cliente, setCliente] = useState({});

    const history = useHistory();

    useEffect(() => {
        
    
          /* ... FECH ... */
         fetch('https://clientes.piscinaelcarmen.com/public/index.php/datos',{
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${localStorage.getItem('token')}`
            } ,
          })
          .then( response => response.json())
          .then(
              response => {
                    setEntradas(response.datos.entradas)
                    setTemporada(response.datos.temporada)
                })
                .catch(
                   error=> console.log(error) 
          );
    
      }, [])

   
    useEffect(() => {
        const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if(counter=== 0){
          setCodigo(0)
      }
    return () => clearInterval(timer);
    },[counter])
 
    
    


    function enviarHandle(e){
        e.preventDefault();

        /* ... FECH ... */
     fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes/pase',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }   ,
        body: JSON.stringify({
           codigo: textCodigo
        })  
      })
      .then( response => response.json())
      .then(
          response => {
              if(response.cliente ===false){
                setCodigo(2);
              }else{

                  setCliente(response.cliente);
                  setEntradas(response.entradas)
                  setCodigo(1);
                  verificacionF() ;
                }
                
               
            })
            .catch(
               setCodigo(0)
      );

        
        
        setCounter(10);
        setTextCodigo('') ;       
        
    }
    function codigoHandle(e) {
        e.preventDefault();
        setTextCodigo(e.target.value)
    }

    function cardCliente(){
        
        function handleVer(e) {
            e.preventDefault();
            history.replace(`perfil?id=${cliente.id}`);
    
        }
        
        
            return(
            
                
                <div className="contai0ner bg-light border  rounded p-0 "   >
                 <img src={cliente.imagen} className="rounded mx-auto d-block mt-3" height="150"  alt=""/>
                <div className="card-body text-center">
                <div className="progress w-100 col-12 mb-3" Style="height: 2px;"> </div>
                <h4 className="font-weight-bold text-dark">{cliente.nombre_completo} </h4>
                <h6 className="font-weight-light text-dark">Número de socio: <span className='font-weight-bold text-dark'>{cliente.num_socio}</span></h6>
                <div className="progress w-100 col-12 mb-3" Style="height: 2px;"> </div>
                <h6 className="font-weight-light font-weight-bold text-info h5">{cliente.abono}</h6>
                <h6 className="font-weight-light text-dark font-italic mb-3 ">{cliente.observaciones} </h6>
                <div className="progress w-100 col-12 mb-3" Style="height: 2px;"> </div>
                
                <buttom className ='btn btn-outline-info m-0 w-100' onClick={(e)=>handleVer(e)}><i className="icon ion-md-eye h5"> </i>Ver Cliente</buttom>
            </div>
            </div>
       
        
        )
        

    }
    
    
    function cargaF(){
        return(
            <div className="container rounded alert  bg-info py-0 text-right">
                    <div className="text-right py-3 px-2">
                    <div className="spinner-border text-light py-2" role="status" Style="width: 2.5rem; height: 2.5rem;"></div>
                    </div>
            </div>  
        )
    };
    function entradaF(){
       
        
        return(
            <div>
           

            <div className="container rounded alert  bg-success py-0 text-right">
            <h2 className='font-weight-bold my-3 mr-4 text-light'>ENTRADA <i className="icon ion-md-return-right"></i></h2>
            </div>
            <Delay wait={500}>
            {cardCliente()}
             </Delay> 
 
            
            </div>
            
            
            
        )
    };
    function salidaF(){
        return(
            <div>
              

            <div className="container  alert border-success0  bg-warning py-0 text-right">
            <h2 className='font-weight-bold  my-3 mr-4 text-light'>SALIDA <i className="icon ion-md-return-left"></i></h2>
        </div> 
        
        
            {cardCliente()}
             
             
            </div>
        )
    };
    function errorF(){
        return(
 
            <div className="container ounded alert  bg-danger py-0 text-right">
            <h2 className='font-weight-bold my-3 mr-4 text-light'>NO VALIDO <i className="icon ion-md-alert"></i></h2>
        </div>  
        )
    };
    
    
    
    function verificacionF(){
        
         if(codigo === 1 ){
          
            if(cliente.entrada=== true){
                
                return( salidaF() )
                
            }else if (cliente.entrada === false){
                return(entradaF())
                
                }
            }else if(codigo=== 2){
                return(errorF())

        } else{
            return(cargaF())
        } 
  
        

    }
    function restarEntrada(e) {
        e.preventDefault();
                  
        
         /* ... FECH ... */
     fetch('https://clientes.piscinaelcarmen.com/public/index.php/datos/resta',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }   
        })
        .then( response => response.json())
        .then(response => {setEntradas(response.entradas)})
        .catch( );
        
    }
        
    
    function sumarEntrada(e) {
        e.preventDefault();
       
          
        
         /* ... FECH ... */
     fetch('https://clientes.piscinaelcarmen.com/public/index.php/datos/suma',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }   
        })
        .then( response => response.json())
        .then(response => {setEntradas(response.entradas)})
        .catch( );
        
    }

    function resetEntrada(e) {
        e.preventDefault();
       
            
            Swal.fire({
                title: '¿Estas seguro?',
                text: "El contador de entradas se pondra a 0, además todos los pases se pondran en modo salida.",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#6c757d',
                confirmButtonColor: '#17a2b8',
                confirmButtonText: 'Si, Reset',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    title: 'Reseteado!',
                    text: "El contador se ha puesto a 0",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: "2000",
                    
                    })
                             /* ... FECH ... */
                    fetch('https://clientes.piscinaelcarmen.com/public/index.php/datos/reset',{
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                        'Content-Type': 'application/json',
                        "Authorization" : `Bearer ${localStorage.getItem('token')}`
                        }   
                        })
                        .then( response => response.json())
                        .then(response => {setEntradas(response.entradas)})
                        .catch( );
                                }
              })
        
        
    }
    
  
    return(
    
    <div className ="container mt-5"  >
        <div className="row">
            <div className='col-lg-8 mb-4'>
                <div className="container bg-light border  rounded">
                <h2 className='font-weight-bold text-info mt-3'><i className="icon ion-md-card"> </i> PASE: </h2>
                 <div className="progress w-100 col-12 mb-2" Style="height: 2px;"> </div>

                 <div className="input-group my-3  border-info rounded">

                     <form className="form-row w-100 ml-3">
                         

                    <input type="text" id='pase' className="form-control0 rounded-left border border-info font-weight-bold col-10 col-md-11" onChange={(e)=>codigoHandle(e)} value={textCodigo} placeholder="Introduce Codigo de Barras" aria-label="Introduce Codigo de Barras" aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <button className=" py-2 px-3 btn-info rounded-right border border-info " for='pase' onClick={(e)=>enviarHandle(e)}><i className="icon ion-md-arrow-round-forward "></i></button>
                         
                     </div>
                     </form>
                </div>



                 <div className="progress w-100 col-12 mb-3" Style="height: 2px;"> </div>
                 <p className=' text-secondary my-3'> <i className="icon ion-md-sunny text-info"> </i> Temporada: <span className='font-weight-bold text-dark'>{temporada}</span></p>
                 <p className=' text-secondary my-3'> <i className="icon ion-md-calendar text-info"> </i> Fecha: <span className='font-weight-bold text-dark'>{ new Date().toLocaleString().slice(0,10)}</span></p>
                 <p className=' text-secondary my-3'> <i className="icon ion-md-stats text-info"> </i> Entradas: <span className='font-weight-bold text-info'>{entradas}</span></p>
                 <div className="progress w-100 col-12 my-3" Style="height: 2px;"> </div>

                    <div className="d-flex justify-content-end">
                        <div className="btn-group  mb-4 mt-0  " role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-outline-secondary"onClick={(e)=>restarEntrada(e)}>-1</button>
                            <button type="button" className="btn btn-info"onClick={(e)=>resetEntrada(e)}>Reset</button>
                            <button type="button" className="btn btn-outline-secondary"onClick={(e)=>sumarEntrada(e)}>+1</button>
                        </div>
                    </div>
                </div>
            </div>  
            
            <div className=' col-lg-4 '>
            
            {verificacionF()}
             {/* <h1>{counter}</h1> 
 */}
 

            

            </div>  
        </div>
      
    </div>
    );
    
}


export {Consulta};