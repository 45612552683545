import {useState } from 'react';
import {useEffect} from 'react';
import {ClienteList} from './clienteList'

function Busqueda (){

    const [clientes, setClientes] = useState([    ]);
    const [filtro, setFiltro] = useState([]);
    
    const [texto, setTexto] = useState("");
    const [busquedapor, setBusquedaPor] = useState('1');

    useEffect(() => {
        
        /* ... FECH ... */
        fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes',{
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
          } 
        }).then( response => response.json())
        .then(
            response => {
              setClientes(response.clientes);
             // setFiltro(response.clientes);
              const sorted0 = ([...response.clientes].sort((a, b) => (Number(a.numsocio) > Number(b.numsocio) ? 1 : Number(a.numsocio) < Number(b.numsocio) ? -1 : 0)))
              setFiltro(sorted0);
             
              
            }).catch(error=> console.log(error) );
            
      }, [])

      function handleTexto(e) {
        e.preventDefault();
        setTexto(e.target.value);
        setFiltro(clientes)
      }
      function handleBuscar(e) {
        e.preventDefault();
        
        
        switch (busquedapor) {
          case '1':
            setFiltro(filtro.filter(cliente => (cliente.nombre_completo.toLowerCase().includes(texto.toLowerCase())===true)))
            break;
          case '2':
            setFiltro(filtro.filter(cliente => (cliente.dni.toLowerCase().includes(texto.toLowerCase())===true)))
            break;
          case '3':
            setFiltro(filtro.filter(cliente => (cliente.numsocio == (texto))))
            break;
          case '4':
            setFiltro(filtro.filter(cliente => (cliente.codigo.toLowerCase().includes(texto.toLowerCase())===true)))
            break;
          case '5':
            setFiltro(filtro.filter(cliente => (cliente.abono.toLowerCase().includes(texto.toLowerCase())===true)))
            break;
          case '6':
            setFiltro(filtro.filter(cliente => (cliente.temporada.toLowerCase().includes(texto.toLowerCase())===true)))
            break;
           
            }
      }

      function handleOrden(e) {
        e.preventDefault();
        
        switch (e.target.value) {
          case '1':
            const sorted = ([...filtro].sort((a, b) => (Number(a.numsocio) > Number(b.numsocio) ? 1 : Number(a.numsocio) < Number(b.numsocio) ? -1 : 0)))
            setFiltro(sorted);
            break;
            case '2':
              const sorted2 = [...filtro].sort((a, b) => (Number(a.numsocio) < Number(b.numsocio) ? 1 : Number(a.numsocio) > Number(b.numsocio) ? -1 : 0))
              setFiltro(sorted2);
              break;
            case '3':
              const sorted3 = ([...filtro].sort((a, b) => (a.nombre_completo > b.nombre_completo ? 1 : a.nombre_completo < b.nombre_completo ? -1 : 0)))
              setFiltro(sorted3);
              break;
          }
          
      }

  
    return(
    <div className ="container mt-5 bg-light pt-3 px-5 border  rounded ">
    <div className =" "  >
    <h2 className='font-weight-bold text-info'><i className="icon ion-md-search"> </i> BUSQUEDA:</h2>
      <div className="progress w-100 col-12 mb-4" Style="height: 2px;"> </div>
    
      <div className="form-row">

        <div className="form-group  col-9 col-lg-10 ">
        <input type="text" className="form-control " onChange={(e)=>handleTexto(e)} placeholder="Buscar cliente" aria-label="texto de busqueda" aria-describedby="basic-addon2"/>
        </div>
        <div className="form-group  col-3 col-lg-2">
        <button className="btn btn-info w-100 " type="button" onClick={(e)=>handleBuscar(e)}><i className="icon ion-md-search h6"> </i> Buscar</button>
        </div>

        
        <div className="form-group  col-6">
        <label for="inputAbono">Busqueda por:</label>
                <select className="custom-select mr-sm-2 list-group-item-info" id="inlineFormCustomSelect"  onChange={(e)=>{setBusquedaPor(e.target.value);  setFiltro(clientes)}}>
                    <option value="1">Nombre y Apellidos</option>
                    <option value="2">DNI</option>
                    <option value="3">Numero de Socio</option>
                    <option value="4">Código de Barras</option>
                    <option value="5">Tipo de Abono</option>
                    <option value="6">Temporada</option>
                </select>
        </div>
        <div className="form-group  col-6">
        <label for="inputAbono">Ordenar por:</label>
                <select className="custom-select mr-sm-2 list-group-item-info" id="inlineFormCustomSelect" onChange={(e)=>handleOrden(e)}>
                    <option value="1">Ascendente Numero de Socio</option>
                    <option value="2">Descendente Numero de Socio</option>
                    <option value="3">Alfabeticamente</option>
                </select>
        </div>
      </div>
      
      <div className="progress w-100 col-12 mb-50 mt-4" Style="height: 2px;"> </div>
        <ClienteList clientes ={filtro} setClientes={setFiltro}/>
        
    </div>
    
<div >

  
</div>


    </div>
    );
    
}


export {Busqueda};