import {useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";


function Perfil (){

  const history = useHistory();
  const[editar, setEditar] =useState('');
  const[guardar, setGuardar] =useState('d-none');
  const[disable, setDisable] =useState(true);
  const[debe, setDebe] =useState(0);
  const [abonos, setAbonos] = useState([{}]);
  const [imagen, setImagen] = useState('');
  const [imagenmostrar, setImagenMostrar] = useState('');
  const[perfil, setPerfil] =useState({
  /*   nombre: "Pablo", apellidos: "Gomez Osuna", telefono: "635635635", dni: "50644954G", nsocio: "3", codigo:"895689568956",
    abono:"Abono de Temporada", temporada :"2021",  pagado: 50, precio : 150 */
  })
  const anos = [
    2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035,2036,2037,2038,2039,2040,
    2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052,2053,2054,2055,2056,2057,2058,2059,2060,
    2061,2062,2063,2064,2065,2066,2067,2068,2069,2070,2071,2072,2073,2074,2075,2076,2077,2078,2079,2080,
    2081,2082,2083,2084,2085,2086,2087,2088,2089,2090,2091,2092,2093,2094,2095,2096,2097,2098,2099,2100
    ];

  useEffect(() => {
    const valores = window.location.search;
        const urlParams = new URLSearchParams(valores);
        const id = urlParams.get('id');

      /* ... FECH ... */
     fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes/perfil',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        }   ,
        body: JSON.stringify({
           id: id
        })  
      })
      .then( response => response.json())
      .then(
          response => {
                setPerfil(response.cliente);
                //setImagen(response.cliente.imagen);
                setImagenMostrar(response.cliente.imagen);
                const resta =response.cliente.precio-response.cliente.pagado;
                setDebe(resta)
            })
            .catch(
               error=> console.log(error) 
      );
          /* ... FECH ... */
      fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos',{
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : `Bearer ${localStorage.getItem('token')}`
        } ,
      })
      .then( response => response.json())
      .then(
          response => {
                setAbonos(response.abonos)
            })
            .catch(
              error=> console.log(error) 
      );

  }, [])



  function handleEditar(e) {
    e.preventDefault();
    setEditar('d-none');
    setGuardar('');
    setDisable(!disable);
    
  }
  
  function handleGuardar(e) {
    e.preventDefault();
    

    Swal.fire({
      title: '¿Deseas Guardar?',
      text: "Se sobrescribirán los datos",
      icon: 'info',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
      confirmButtonColor: '#17a2b8',
      confirmButtonText: 'Si, Guardar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        
          const valores = window.location.search;
          const urlParams = new URLSearchParams(valores);
          const id = urlParams.get('id');
           /* ... FECH ... */
        fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes/editar',{
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
          }   ,
          body: JSON.stringify({
             id: id,
             nombre: perfil.nombre,
             apellidos: perfil.apellidos,
             numsocio: perfil.nsocio,
             dni: perfil.dni,
             telefono: perfil.telefono,
             codigo: perfil.codigo,
             pagado: perfil.pagado,
             temporada: perfil.temporada,
             abono: perfil.abono
          })  
        })
        .then( response => response.json())
        .then(
            response => {
              if(response.editarcliente === true){
                Swal.fire({
                   position: 'top-end', 
                  title: 'Actualizacion Completada',
                  toast: true,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: "2000",
                  })
                     /* FECH IMAGEN con el form Data */
                  if(imagen !== ''){
                     const formDataImagen = new FormData();
                     formDataImagen.append('imagen', imagen);
                           /* ... FECH DATOS... */
                     fetch(`https://clientes.piscinaelcarmen.com/public/index.php/clientes/imagen/${perfil.id}`,{
                         method: 'POST',
                         mode: 'cors',
                         headers: {
                           "Authorization" : `Bearer ${localStorage.getItem('token')}`
                         }   ,
                         body:  formDataImagen
                     })
                     .then( response => response.json())
                     .then(
                      setTimeout(() => {
                        history.go(0);
                    }, 500)
                     )
                     .catch (error => console.log(error) );
                  }

              }
              
              
                 
              })
              .catch(error =>( Swal.fire({
                /* position: 'top-end', */
                title: 'Error al actualizar',
                text: 'Error en la conexión al servidor',
                
                icon: 'error',
                showConfirmButton: false,
                timer: "3000",
                }) ));

          setEditar('');
          setGuardar('d-none');
          setDisable(!disable);
      }
    })
    
  }
  function handleEliminar(e) {
    e.preventDefault();
    Swal.fire({
      title: '¿Quieres Eliminar?',
      text: "Se eliminará el cliente por completo",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
      confirmButtonColor: '#17a2b8',
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const valores = window.location.search;
          const urlParams = new URLSearchParams(valores);
          const id = urlParams.get('id');

          /* ... FECH ... */
          fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes/eliminar',{
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              "Authorization" : `Bearer ${localStorage.getItem('token')}`
            }   ,
            body: JSON.stringify({
               id: id,
            })  
          })
          .then( response => response.json())
          .then(
              response => {
                if(response.eliminarcliente === true){
                  Swal.fire({
                    position: 'top-end',
                    title: 'Eliminado correctamente',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: "3000",
                    toast: true,
                    })
                    history.replace("busqueda");
  
                }
                   
                })
                .catch(error =>( Swal.fire({
                  /* position: 'top-end', */
                  title: 'Error al eliminar',
                  text: 'Error en la conexión al servidor',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: "3000",
                  }) ));
                  
          
      }
    })
    
  }
  function handleDebe(e) {
   
    const resta =perfil.precio-e.target.value;
    setDebe(resta);
    setPerfil({...perfil, pagado: e.target.value})
    
  }

  const handleImagen = (e)=> {
    setImagen(e.target.files[0]);
    console.log(URL.createObjectURL(e.target.files[0]));
    setImagenMostrar(URL.createObjectURL(e.target.files[0]));
    
  }

  
  
    return(
    
    <div className ="container mt-5  bg-light pt-3 px-5 border  rounded"  >

      <h2 className='font-weight-bold text-info'><i className="icon ion-md-person"> </i> PERFIL CLIENTE:</h2>
      <div className="progress w-100 col-12 mb-4" Style="height: 2px;"> </div>
      
       <form className="form-row" >
       

         <div className="form-group col-lg-8">
              
            <div className="form-row">
              <div className="form-group col-md-12">
                  <label for="inputNombre">Nombre</label>
                  <input type="text" className="form-control font-weight-bold" id="inputNombre" placeholder="Nombre"  onChange={(e)=>(setPerfil({...perfil, nombre: e.target.value}))} defaultValue={perfil.nombre} disabled={disable}></input>
              </div>
              <div className="form-group col-md-12">
                  <label for="inputApellidos">Apellidos</label>
                  <input type="text" className="form-control font-weight-bold" id="inputApellidos" placeholder="Apellidos"  onChange={(e)=>(setPerfil({...perfil, apellidos: e.target.value}))} defaultValue={perfil.apellidos} disabled={disable}></input>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                  <label for="inputTelefono">Telefono</label>
                  <input type="text" className="form-control font-weight-bold" id="inputTelefono" placeholder="Telefono" onChange={(e)=>(setPerfil({...perfil, telefono: e.target.value}))} defaultValue={perfil.telefono} disabled={disable}></input>
              </div>
              <div className="form-group col-md-6">
                  <label for="inputDNI">DNI</label>
                  <input type="text" className="form-control font-weight-bold" id="inputDNI" placeholder="DNI" onChange={(e)=>(setPerfil({...perfil, dni: e.target.value}))} defaultValue={perfil.dni} disabled={disable}></input>
              </div>
              
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                  <label for="inputNumeroSocio">Numero de socio</label>
                  <input type="number" className="form-control font-weight-bold" id="inputNumeroSocio" placeholder="Numero de socio" onChange={(e)=>(setPerfil({...perfil, nsocio: e.target.value}))} defaultValue={perfil.nsocio} disabled={disable}></input>
              </div>
              <div className="form-group col-md-6">
                  <label for="inputCBarras">Codigo de Barras</label>
                  <input type="text" className="form-control font-weight-bold" id="inputCBarras" placeholder="Codigo de barras" onChange={(e)=>(setPerfil({...perfil, codigo: e.target.value}))} defaultValue={perfil.codigo} disabled={disable}></input>
              </div>
              
            </div>
            <div className="form-row">
            <div className="form-group col-md-8">
                  <label for="inputAbono">Tipo de Abono</label>
                  <select id="inputAbono" className="custom-select list-group-item-info font-weight-bold" onChange={(e)=>(setPerfil({...perfil, abono: e.target.value}))} disabled={disable}>
                      <option disabled selected>{perfil.abono}</option>
                      {
                        abonos.map((abono)=>{
                          return(<option >{abono.nombre}</option>)
                        }
                      )}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label for="inputAbono">Temporada</label>
                  <select id="inputAbono" className="custom-select list-group-item-info font-weight-bold" onChange={(e)=>(setPerfil({...perfil, temporada: e.target.value}))} disabled={disable}>
                      <option disabled selected>{perfil.temporada}</option>
                      {
                        anos.map((ano)=>{
                          return(<option >{ano} </option>)
                        }
                      )}
                  </select>
                </div>
                  
                  
              
              
            </div>
            <div className="progress w-100 col-12 mb-2 mt-2" Style="height: 2px;"> </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                  <label for="inputCBarras">Pagado</label>
                  <input type="number" className="form-control font-weight-bold" id="inputPagado" placeholder="Pagado"  onChange={(e)=>handleDebe(e)} defaultValue={perfil.pagado} disabled={disable}></input>
              </div>
              <div className="form-group col-md-4">
                  <label for="inputNumeroSocio">Debe</label>
                  <input type="number" className="form-control font-weight-bold" id="inputDebe" placeholder="Debe"  value={debe}   disabled></input>
              </div>
              <div className="form-group col-md-4">
                  <label for="inputCBarras">Precio Abono</label>
                  <input type="number" className="form-control font-weight-bold" disabled id="inputPrecio" placeholder="Precio"  defaultValue={perfil.precio}  ></input>
              </div>
              
            </div>
            
        </div>
        <div className="form-group col-lg-4 col-6">
         <div className="card mt-lg-4 ml-lg-4"   >
            <img src={imagenmostrar} className="card-img-top" alt=""/>
            
            <div className={`card-body text-center ${guardar}`}> 
            <label for="file-input" className='w-100 m-0 p-0' >
            <buttom className ='btn btn-outline-info w-100 ' disabled={disable} ><i className="icon ion-md-sync h5"> </i>Cambiar Imagen</buttom>
            </label>
            <input type="file" id="file-input" className='d-none'  onChange={handleImagen}  />
            </div>

            
         </div>
         </div>
         <div className="progress w-100 col-12 mb-4" Style="height: 2px;"> </div>
         <div className="form-group  col-12 mt-0 mb-4 text-right">

          <button type="reset" className="btn btn-outline-danger mr-2"  onClick={(e)=>handleEliminar(e)}><i className="icon ion-md-trash h5"> </i>Eliminar</button>
          <button type="submit" className={`btn btn-outline-info px-4 ${editar}`} onClick={(e)=>handleEditar(e)}><i className="icon ion-md-create h5"> </i>Editar</button>
          <button type="submit" className={` btn btn-info px-4 ${guardar} `}  onClick={(e)=>handleGuardar(e)}><i className="icon ion-md-save h5"> </i>Guardar</button>
          </div>
         
      </form>


    </div>
    );
    
}


export {Perfil};