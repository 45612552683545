import {useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import imgdefault from './../ImagesApp/default.jpg';

function Registro (){
  const [abonos, setAbonos] = useState([{}]);
  const anos = [
    2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034,2035,2036,2037,2038,2039,2040,
    2041,2042,2043,2044,2045,2046,2047,2048,2049,2050,2051,2052,2053,2054,2055,2056,2057,2058,2059,2060,
    2061,2062,2063,2064,2065,2066,2067,2068,2069,2070,2071,2072,2073,2074,2075,2076,2077,2078,2079,2080,
    2081,2082,2083,2084,2085,2086,2087,2088,2089,2090,2091,2092,2093,2094,2095,2096,2097,2098,2099,2100
  ];
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [dni, setDni] = useState('');
  const [numSocio, setNumSocio] = useState('');
  const [codigo, setCodigo] = useState('');
  const [abono, setAbono] = useState('');
  const [temporada, setTemporada] = useState('');
  const [imagen, setImagen] = useState('');

  useEffect(() => {
    /* ... FECH ... */
   fetch('https://clientes.piscinaelcarmen.com/public/index.php/abonos',{
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : 'Bearer '+ localStorage.getItem('token')
      } ,
    })
    .then( response => response.json())
    .then(
        response => {
              setAbonos(response.abonos)
          })
          .catch(
             error=> console.log(error) 
    );

  }, [])

  function handleRegistrar(e) {
    e.preventDefault();
    if(!((nombre==='')||(apellidos ==='')||(numSocio ==='')||(dni ==='')||(telefono ==='')||(codigo ==='')||(temporada ==='')||(abono ==='') )){
       
   
      
      
      
      /* ... FECH ... */
        fetch('https://clientes.piscinaelcarmen.com/public/index.php/clientes/nuevo',{
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('token')}`
          }   ,
          body: JSON.stringify({
             nombre: nombre,
             apellidos: apellidos,
             numsocio: numSocio,
             dni: dni,
             telefono: telefono,
             codigo: codigo,
             temporada: temporada,
             abono: abono
          })  
        })
        .then( response => response.json())
        .then(
            response => {
              if(response.nuevocliente === true){
                Swal.fire({
                  /* position: 'top-end', */
                  title: 'Registro Completado',
                  
                  icon: 'success',
                  showConfirmButton: false,
                  timer: "2000",
                  })
                     /* FECH IMAGEN con el form Data */

                  const formDataImagen = new FormData();
                  formDataImagen.append('imagen', imagen);
                        /* ... FECH DATOS... */
                  fetch(`https://clientes.piscinaelcarmen.com/public/index.php/clientes/imagen/${response.idnuevo}`,{
                      method: 'POST',
                      mode: 'cors',
                      headers: {
                        "Authorization" : `Bearer ${localStorage.getItem('token')}`
                      }   ,
                      body:  formDataImagen
                  })
                  .then( response => response.json())
                  .then()
                  .catch (error => console.log(error) );

              }
              setNombre("");
              setApellidos("");
              setNumSocio("");
              setDni("");
              setTelefono("");
              setCodigo("");
              setImagen("");
              
                 
              })
              .catch(error =>( Swal.fire({
                /* position: 'top-end', */
                title: 'Error en el Registro',
                text: 'Error en la conexión al servidor',
                
                icon: 'error',
                showConfirmButton: false,
                timer: "3000",
                }) ));
          }else{
            Swal.fire({
               position: 'top-end', 
              title: 'ERROR: Campos erroneos o vacíos',
              
              icon: 'error',
              showConfirmButton: false,
              timer: "3000",
              toast: true,
              })

          }


    

}

  function changeimagen() {
    if(imagen === ''){
      return (<img src={imgdefault} className="card-img-top" alt=""/>)
    }else{
      return <img src={URL.createObjectURL(imagen)} className="card-img-top" alt=""/>
    }
    
  }
  const handleImagen = (e)=> {
    setImagen(e.target.files[0]);
    console.log(URL.createObjectURL(e.target.files[0]));
    
    
  }

  
    return(
    
    <div className ="container my-5 bg-light pt-3 px-5 border  rounded"  >

      <h2 className='font-weight-bold text-info'><i className="icon ion-md-person-add"> </i> NUEVO CLIENTE:</h2>
      <div className="progress w-100 col-12 mb-4" Style="height: 2px;"> </div>
      
       <form className="form-row" >
       

         <div className="form-group col-lg-8">
              
            <div className="form-row">
              <div className="form-group col-md-12">
                  <label for="inputNombre">Nombre</label>
                  <input type="text" className="form-control" id="inputNombre" placeholder="Nombre" onChange={(e)=>{setNombre(e.target.value)}}  value={nombre} ></input>
              </div>
              <div className="form-group col-md-12">
                  <label for="inputApellidos">Apellidos</label>
                  <input type="text" className="form-control" id="inputApellidos" placeholder="Apellidos"onChange={(e)=>{setApellidos(e.target.value)}}  value={apellidos}></input>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                  <label for="inputTelefono">Telefono</label>
                  <input type="text" className="form-control" id="inputTelefono" placeholder="Telefono" onChange={(e)=>{setTelefono(e.target.value)}}  value={telefono}></input>
              </div>
              <div className="form-group col-md-6">
                  <label for="inputDNI">DNI</label>
                  <input type="text" className="form-control" id="inputDNI" placeholder="DNI"onChange={(e)=>{setDni(e.target.value)}}  value={dni}></input>
              </div>
              
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                  <label for="inputNumeroSocio">Numero de socio</label>
                  <input type="number" className="form-control" id="inputNumeroSocio" placeholder="Numero de socio"onChange={(e)=>{setNumSocio(e.target.value)}}  value={numSocio}></input>
              </div>
              <div className="form-group col-md-6">
                  <label for="inputCBarras">Codigo de Barras</label>
                  <input type="text" className="form-control" id="inputCBarras" placeholder="Codigo de barras" onChange={(e)=>{setCodigo(e.target.value)}}  value={codigo}></input>
              </div>
              
            </div>
            <div className="form-row">
            <div className="form-group col-md-8">
                  <label for="inputAbono">Tipo de Abono</label>
                  <select id="inputAbono" className="custom-select list-group-item-info" onChange={(e)=>{setAbono(e.target.value)}}  >
                      <option disabled selected>Tipo de Abono</option>
                       {
                        abonos.map((abono)=>{
                          return(<option >{abono.nombre}</option>)
                        }
                      )} 
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label for="inputAbono">Temporada</label>
                  <select id="inputAbono"  className="custom-select list-group-item-info"  onChange={(e)=>{setTemporada(e.target.value)}}>
                      <option disabled selected>Temporada</option>
                      {
                        anos.map((ano)=>{
                          return(<option >{ano} </option>)
                        }
                      )}
                  </select>
                </div>
                  
              
              
            </div>
            
        
            
        </div>
        <div className="form-group col-lg-4 col-6">
         <div className="card mt-lg-4 ml-lg-4"   >
          {changeimagen()}
            <div className="card-body text-center">
            <label for="file-input" className='w-100 m-0 p-0' >
              <buttom className ='btn btn-outline-info w-100'><i className="icon ion-md-arrow-round-up h5"> </i>Subir Imagen</buttom>
            </label>
            <input type="file" id="file-input" className='d-none' onChange={handleImagen} />
            </div>
         </div>
         </div>
         <div className="progress w-100 col-12" Style="height: 2px;">
        {/* <div className="progress-bar"  Style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div> */}
        </div>
    
         <div className="form-group  col-12 mt-3 mb-4 text-right">

            <button type="reset" className="btn btn-outline-secondary mr-2"><i className="icon ion-md-undo h5"> </i>Cancelar</button>
            <button type="submit" className="btn btn-info px-4" onClick={(e)=>handleRegistrar(e)}><i className="icon ion-md-person-add h5"> </i>Registrar</button>
         </div>
      </form>


    </div>
    );
    
}


export {Registro};