
import React from 'react';
import {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {NavPortero} from './nav-portero'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Consulta} from './consulta'
import {Busqueda} from './busqueda'
import {Registro} from './registro'
import {Perfil} from './perfil.js'
import {Login} from './login.js'

function Portero() {
  const history = useHistory();
  useEffect(()=>{
       
    const user = jwt_decode(localStorage.getItem('token'));
    if(!user.roles.includes('Portero') ){
      localStorage.removeItem("token");
        history.push("/")
    }
    

}, []);
  return (
    <div className="">
      
    <React.StrictMode>
       <Router>
      <NavPortero/>
      <Switch>
       

        <Route  path="/" exact  component={Login} />
        <Route  path="/portero/inicio" component={Consulta} />
        <Route path="/portero/busqueda" component={Busqueda} />
        <Route path="/portero/perfil" component={Perfil} />
        <Route path="/portero/registro" component={Registro} />
      
        
      </Switch>
    </Router>
  </React.StrictMode>
     
        
    </div>
  );
}

export {Portero};
