import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Admin} from './component/Admin.js'
import {Portero} from './component/Portero.js'
import {Login} from './component/login.js'
import logo from './ImagesApp/developedPabloG.png'


function App() {
  return (
    <div className="fondo">
      
    <React.StrictMode>
       <Router>
      
      <Switch>
       

       
        <Route path="/" exact component={Login} />
        <Route  path="/admin/"  component={Admin} />
        <Route  path="/portero/"  component={Portero} />
      
        
      </Switch>
    </Router>
  </React.StrictMode>
  <img src={logo} className='dev' alt=""/>
  
    </div>
  );
}

export default App;
